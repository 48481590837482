"use client";

import MincaLink from "components/common/MincaLink";
import { useSupabase } from "components/supabase-provider";
import { useRouter, useSearchParams } from "next/navigation";
import { useState } from "react";
import { flashInfo } from "utils/dom";
import { BASE_URL } from "utils/site";
const LoginPage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [email, setEmail] = useState("");
  const {
    supabase
  } = useSupabase();
  const router = useRouter();
  const searchParams = useSearchParams();
  const getRedirectUrlFromSearchParams = () => {
    let redirectUrl = "";
    const search = searchParams.get('redirectedFrom');
    if (search) {
      // the query param is encoded, so we need to decode it
      try {
        redirectUrl = decodeURIComponent(search);
      } catch {
        // deliberate no-op, empty redirectUrl handled later
      }
    }
    return redirectUrl;
  };
  const onForgotPassword = async (email: string) => {
    if (!email) {
      setErrorMessage("Please enter your email");
      return;
    }
    const {
      error
    } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${BASE_URL}auth/password`
    });
    if (error) {
      setErrorMessage(error.message);
    } else {
      flashInfo("Check your email for a password reset link");
    }
  };
  const onMagicLink = async (email: string) => {
    if (!email) {
      setErrorMessage("Please enter your email");
      return;
    }
    setErrorMessage("");
    const redirectUrl = getRedirectUrlFromSearchParams() || "/pending";
    const {
      data,
      error
    } = await supabase.auth.signInWithOtp({
      email: email,
      options: {
        shouldCreateUser: false,
        emailRedirectTo: `${BASE_URL}auth/landing/pending?confirmationUrl=${redirectUrl}`
      }
    });
    if (error) {
      setErrorMessage(error.message);
    } else {
      flashInfo("Check your email for a link to log in");
    }
  };
  const onSubmit = async (e: any) => {
    // reset error message
    setErrorMessage("");
    setIsUpdating(true);
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    // log the user in and redirect to the dashboard
    const {
      data: authData,
      error: authError
    } = await supabase.auth.signInWithPassword({
      email,
      password
    });
    if (authError) {
      setErrorMessage(authError.message);
      setIsUpdating(false);
      return;
    }
    router.push(getRedirectUrlFromSearchParams() || "/pending");
  };
  return <div className="flex flex-col items-center p-8 rounded-lg bg-white border border-gray-200 shadow-md w-full" data-sentry-component="LoginPage" data-sentry-source-file="LoginPage.tsx">
      <form className="w-full" onSubmit={onSubmit}>
        <h1 className="text-black_primary mb-5 text-center w-full">
          Log in to Minca
        </h1>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-1 w-full">
            <label htmlFor="email" className="text-sm font-medium text-gray-800">
              Email
            </label>
            <input id="email" type="email" className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-orange_cone" placeholder="Enter your email" value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="flex flex-col gap-1 w-full">
            <label htmlFor="password" className="text-sm font-medium text-gray-800 mb-1">
              Password
            </label>
            <input id="password" type="password" className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-orange_cone" placeholder="Enter your password" />
          </div>
        </div>
        <p className="text-sm text-red_error mt-2">{errorMessage}</p>
        <div className="text-sm text-center text-blue-600 hover:text-blue-800  cursor-pointer mt-2" onClick={() => onForgotPassword(email)}>
          Forgot Password?
        </div>
        <div className="text-sm text-center text-blue-600 hover:text-blue-800 cursor-pointer mt-2" onClick={() => onMagicLink(email)}>
          Email me a Sign in Link
        </div>
        <button disabled={isUpdating} className="mt-5 button flex mx-auto">
          {isUpdating ? "Logging in..." : "Log in"}
        </button>
      </form>
      <MincaLink type="orange-hyperlink" className="mt-2 text-sm" href="/auth/signup" onClick={e => e.stopPropagation()} disabled={isUpdating} data-sentry-element="MincaLink" data-sentry-source-file="LoginPage.tsx">
        Signup
      </MincaLink>
    </div>;
};
export default LoginPage;