import ClipLoader from "react-spinners/ClipLoader";
type hexColor = string;
const Spinner = ({
  color = "orange_cone",
  size = 100,
  speedMultiplier = 0.8,
  containerClassName = ""
}: {
  color?: "orange_cone" | "black" | "white" | `#${hexColor}`;
  size?: number;
  speedMultiplier?: number;
  containerClassName?: string;
}) => {
  // TODO: Somehow, maybe tailwind color substitutions, either here
  // or when specifying the color option at compile time.

  if (color === "orange_cone") {
    color = "#FF763B";
  } else if (color === "black") {
    color = "#000000";
  } else if (color === "white") {
    color = "#FFFFFF";
  }
  return <div className={`flex items-center justify-center ${containerClassName}`} data-sentry-component="Spinner" data-sentry-source-file="Spinner.tsx">
      <ClipLoader color={color} loading={true} size={size} speedMultiplier={0.6} aria-label="Loading Spinner" data-testid="loader" data-sentry-element="ClipLoader" data-sentry-source-file="Spinner.tsx" />
    </div>;
};
export default Spinner;