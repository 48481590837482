/*
Convert:

foo[0][bar]: "baz"
foo[0][fizz]: "buzz"
foo[0][nested][0][yolo]: "for real"

to

{
  foo: [
    {
      bar: "bazz",
      fizz: "buzz"
      nested: [{
        yolo: "for real"
      }]
    }
  ]
}
*/

type Node = { [k: string]: any } | Array<Node>;
export function formStyleDataToJsObject(
  input:
    | [string, FormDataEntryValue][]
    | IterableIterator<[string, FormDataEntryValue]>
): unknown {
  input = [...input];

  // console.info("\n\nformStyleDataToJsObject input", input, "\n");

  // foo[0][bar] -> ["foo", 0, "bar"]
  const keyToPath = (key: string) =>
    [...key.matchAll(/[^(\[\])]+/g)].map((matchData) => {
      // No idea if this is slower or faster than using a regex check
      // but it probably doesn't actually make much difference in reality.
      const asNumber = parseInt(matchData[0]);
      return Number.isNaN(asNumber) ? matchData[0] : asNumber;
    });

  // First, create the root node
  const root: Node = typeof keyToPath(input[0][0])[0] === "string" ? {} : [];

  input.forEach(([key, value]) => {
    if (typeof value !== "string") {
      throw "formStyleDataToJsObject cannot handle File inputs yet";
    }

    const path = keyToPath(key);
    placeValueAtPath(path, value, root);
  });

  // console.info("\n\nformStyleDataToJsObject output", root, "\n");

  return root;
}

// foo[0][fizz] = "foo_0_fizz"
// foo[1][fizz] = "foo_1_fizz"
// bar[baz] = "bar_baz"
// bar[buzz] = "bar_buzz"
const placeValueAtPath = (
  path: (string | number)[],
  value: string,
  node: Node
) => {
  // console.log("ENTER", path, value, node, "\n");

  // If we're a leaf, place the node
  if (path.length === 1) {
    // console.log("LEAF", path, value, node, "\n");
    placeValueAtField(path[0], value, node);
    return;
  }

  // If not create the next node (unless we already did)
  addChildNode(path[0], typeof path[1] === "number" ? "array" : "object", node);

  // console.log("RECURSE", path, value, node, "\n");

  // @ts-ignore, if node is an array, path[0] should be a number, if it's an object, path[0] should be a string.
  const nextNode: Node = node[path[0]];
  placeValueAtPath(path.slice(1), value, nextNode);
};

// Create the child node type at the given field name or index on the parent node.
// Will do nothing if there is already something there.
const addChildNode = (
  fieldNameOrIndex: string | number,
  childNodeType: "array" | "object",
  parentNode: Node
) => {
  if (typeof fieldNameOrIndex === "string" && !Array.isArray(parentNode)) {
    if (parentNode[fieldNameOrIndex] !== undefined) {
      return;
    }
    parentNode[fieldNameOrIndex] = childNodeType === "array" ? [] : {};
  } else if (
    typeof fieldNameOrIndex === "number" &&
    Array.isArray(parentNode)
  ) {
    if (parentNode[fieldNameOrIndex] !== undefined) {
      return;
    }
    parentNode[fieldNameOrIndex] = childNodeType === "array" ? [] : {};
  }
};

const placeValueAtField = (
  fieldName: string | number,
  value: string,
  node: Node
) => {
  if (typeof fieldName === "number") {
    if (!Array.isArray(node)) {
      throw "Cannot place string key in in array.";
    }

    node[fieldName] = value;
  } else if (typeof fieldName === "string") {
    if (typeof node === "object" && !Array.isArray(node)) {
      node[fieldName] = value;
    } else {
      throw "Cannot place number key in object (node must be an array)";
    }
  }
};

/**
 * returns `updated` if it is distinct from `original`.
 *    null is treated as a value.
 *
 * Returns undefined if not.
 *
 * eg:
 *
 *   * ifChangedOrUndefined(null, null) => undefined
 *   * ifChangedOrUndefined("foo", "foo") => undefined
 *   * ifChangedOrUndefined("foo", null) => null
 *   * ifChangedOrUndefined(null, "foo") => foo
 *   * ifChangedOrUndefined("foo", "bar") => "bar"
 */
export function ifChangedOrUndefined<
  T extends string | boolean | number | null,
>(original: T, updated: T): T | undefined {
  if ((original === null) !== (updated === null)) {
    return updated;
  }

  return original === updated ? undefined : updated;
}
