import { isPresent } from "./blank";

class StringBuffer {
  private buffer: Array<string>;
  private length: number = 0;

  constructor(initialLength = 15) {
    this.buffer = new Array<string>(initialLength);
  }

  push(val: string) {
    if (this.length === this.buffer.length) {
      this.buffer = [...this.buffer, ...new Array(this.buffer.length * 2)];
    }

    this.buffer[this.length] = val;
    this.length += 1;

    return this;
  }

  pushPresent(val: string | null | undefined) {
    if (isPresent(val)) {
      this.push(val);
    }

    return this;
  }

  pushIf(condition: boolean, value: string) {
    if (condition) {
      this.push(value);
    }

    return this;
  }

  string(joinOn: string = "") {
    return this.buffer.slice(0, this.length).join(joinOn);
  }
}

// it's just nicer to have a helper to construct it.
const stringBuffer = (initialLength?: number) => {
  return new StringBuffer(initialLength);
};

export { StringBuffer, stringBuffer };
